import { TRENDING_TOPIC_STATUS } from "./../helperMethods/trendingTopics/type";
import { graph, rest } from "@/store/api";
import _ from "lodash";
import moment from "moment";
import gql from "graphql-tag";
import { getInstance } from "@/auth";
import router from "@/router";
import { ActionTree } from "vuex";
import LocalStorageManager from "@/localStorageManager";
import { CardType, FAQ, RootState } from "./types";
import axios from "axios";
import { MessageBox } from "element-ui";
import { setAutoSessionLocalStorage } from "@/helperMethods/autoSession";
import { updateUrlQuery } from "@/helperMethods/util";
import { isShowAdvanced } from "./getters";
import catchRedirect from "@/helpers/catchRedirect";

const actions: ActionTree<RootState, RootState> = {
  SWITCH_BOT: ({ dispatch }, name) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($name: JSON) {
            editorAPI {
              switchBot(name: $name)
            }
          }
        `,
        variables: { name },
      })
      .then(() => {
        dispatch("FETCH_BOT");
      });
  },
  FETCH_BOT: ({ state, dispatch, commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Bot {
              brain

              greeting
              persistentMenu

              content: contentNodes
              trigger: triggerNodes
              entity: entityNodes

              missingContentExpiry: missingContentExpiry
              identicalTriggerExpiry: identicalTriggerExpiry
              missingIntentExpiry: missingIntentExpiry

              event: eventNodes
              tasks: taskNodes

              modules: modules
            }
            Server {
              account
              releaseChannel
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload.data);

        commit("SET_BRAIN", data);
        commit("SET_CONTENT_EXPIRY", data.Bot.missingContentExpiry);
        commit("SET_TRIGGER_EXPIRY", data.Bot.identicalTriggerExpiry);
        commit("SET_INTENT_EXPIRY", data.Bot.missingIntentExpiry);

        commit("SET_MODULES", data);

        commit("SET_GREETING", data.Bot);
        commit("SET_PERSISTENT_MENU", data.Bot);

        commit("SET_NODES", data as any);
        commit("SET_FAQ_FLOW", data.Bot.flows);
        commit("LOADED", 1);
        // Fetch other info
        _.attempt(() => {
          if (_.get(data, "Bot.modules.whatsapp.enabled")) {
            dispatch("GET_WHATSAPP_SERVERS");
          }
        });
      })
      .catch((error) => {
        catchRedirect(error);
      });
  },
  KEYCLOAK_LOGIN: async ({ state, dispatch }) => {
    await rest("post", "keycloak-login");
  },
  FETCH_PROFILE: async ({ state, dispatch, commit }) => {
    const authService = getInstance();
    const payload = authService.user;

    payload.app_metadata = payload["https://login.keyreply.com/appmetadata"];
    payload.user_metadata = payload["https://login.keyreply.com/usermetadata"];

    let username: String = "";
    const isUsrNameAvailable: Boolean = !!_.get(payload, "user_metadata.name");

    if (isUsrNameAvailable) {
      username = payload.user_metadata.name;
    } else if (_.get(payload, "name")) {
      username = payload.name;
    }

    payload.name = username;
    LocalStorageManager.setItem("profile", JSON.stringify(payload));
    commit("SET_USER_PROFILE", payload);
    commit("SET_USER_AUTHENTICATION", payload.app_metadata);
    commit("LOADED", 1);

    await authService.onPostAuthenticated({ rest });

    return state.profile;
  },
  GET_TRAINING_DATA: ({ state, dispatch, commit }, { startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query ($startDate: String, $endDate: String) {
            trainingData(startDate: $startDate, endDate: $endDate)
          }
        `,
        variables: {
          startDate,
          endDate,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const trainingData = _.get(result, "data.trainingData");
        return trainingData;
      });
  },
  GET_TRAINING_DATA_BY_USER: ({ state, dispatch, commit }, { startDate, endDate, userId }) => {
    return graph
      .query({
        query: gql`
          query ($startDate: String, $endDate: String, $userId: String) {
            trainingDataByUser(startDate: $startDate, endDate: $endDate, userId: $userId)
          }
        `,
        variables: {
          startDate,
          endDate,
          userId,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const trainingData = _.get(result, "data.trainingDataByUser");
        return trainingData;
      });
  },
  GET_TRIGGER_DATA: ({ state, dispatch, commit }, { startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query ($startDate: String, $endDate: String) {
            triggerData(startDate: $startDate, endDate: $endDate)
          }
        `,
        variables: {
          startDate,
          endDate,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const triggerData = _.get(result, "data.triggerData");
        return triggerData;
      });
  },
  RUN_TASK: (store, id) => {
    return graph.query({
      query: gql`
        query {
          Bot {
            runTask(${id})
          }
        }
      `,
      fetchPolicy: "network-only",
    });
  },
  FETCH_SERVER_STATUS: ({ state, dispatch, commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Server {
              name
              account
              url
              releaseChannel
              lastRestart
              upSince
              releaseId
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload: any) => {
        commit("SET_SERVER_STATUS", payload.data);
      })
      .catch(() => {});
  },
  FETCH_AUDIT_LOG_BY_PAGE: ({ commit }, { page, pageSize, searchString, startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query (
            $page: Int
            $pageSize: Int
            $searchString: String
            $startDate: String
            $endDate: String
          ) {
            Bot {
              audit: fetchAuditLogByPage(
                page: $page
                pageSize: $pageSize
                searchString: $searchString
                startDate: $startDate
                endDate: $endDate
              )
            }
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          page,
          pageSize,
          searchString,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },

  CREATE_BOT: ({ commit, dispatch }, name) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($name: String) {
            editorAPI {
              createBot(name: $name)
            }
          }
        `,
        variables: { name },
      })
      .then((response) => {
        dispatch("FETCH_BOT");
      });
  },
  GET_MISSING_DETAILS: ({ commit, dispatch }) => {
    return graph
      .query({
        query: gql`
          query {
            editorAPI {
              missingDetailAPI
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_MISSING_DETAILS", response.data.editorAPI);
      })
      .catch((error) => {
        throw error;
      });
  },

  GET_DUPLICATE_TRIGGERS: ({ commit, dispatch }) => {
    return graph
      .query({
        query: gql`
          query {
            editorAPI {
              identicalTriggerAPI
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_DUPLICATE_TRIGGERS", response.data.editorAPI);
      })
      .catch((error) => {
        throw error;
      });
  },

  GET_WILDCARD_TRIGGERS: ({ commit, dispatch }) => {
    return graph
      .query({
        query: gql`
          query {
            editorAPI {
              wildCardTriggerAPI
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_WILDCARD_TRIGGERS", response.data.editorAPI);
      })
      .catch((error) => {
        throw error;
      });
  },

  GET_INTENTS_MISSING_DETAILS: ({ commit, dispatch }) => {
    return graph
      .query({
        query: gql`
          query {
            faqAPI {
              missingIntentDetailAPI
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_INTENT_MISSING_DETAILS", response.data.faqAPI);
      })
      .catch((error) => {
        throw error;
      });
  },

  DELETE_BOT: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            editorAPI {
              deleteBot
            }
          }
        `,
      })
      .then((data) => {
        commit("DELETE_BOT");
      });
  },
  FETCH_USER_INTERACTIONS_BY_PAGE: (
    { commit },
    { userId, page, pageSize, searchString, startDate, endDate }
  ) => {
    return graph
      .query({
        query: gql`
          query (
            $userId: ID!
            $page: Int
            $pageSize: Int
            $searchString: String
            $startDate: String
            $endDate: String
          ) {
            getInteractionsByPage(
              id: $userId
              page: $page
              pageSize: $pageSize
              searchString: $searchString
              startDate: $startDate
              endDate: $endDate
            )
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          userId,
          page,
          pageSize,
          searchString,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_USER_INTERACTIONS_BATCH: ({ commit }, { userIds, startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query ($userIds: [String!], $startDate: String, $endDate: String) {
            getInteractionsBatch(ids: $userIds, startDate: $startDate, endDate: $endDate)
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          userIds,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_BOT_LIST: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            bots
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_BOT_LIST", response.data);
      });
  },

  PUBLISH_DEV_INTO_PROD: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            makerCheckerAPI {
              publishDEVintoPROD
            }
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.makerCheckerAPI.publishDEVintoPROD");
      })
      .catch(() => {});
  },
  PUBLISH_EDITOR_CONTENT: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            approveAllContentNodeChanges
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.approveAllContentNodeChanges");
      })
      .catch((err) => {
        throw err;
      });
  },
  REJECT_CHANGES: ({ commit }, { type, date, departments, customListOfChanges }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation (
            $type: [String]!
            $date: String
            $departments: [String]
            $customListOfChanges: [JSON]
          ) {
            rejectChanges(
              type: $type
              date: $date
              departments: $departments
              customListOfChanges: $customListOfChanges
            )
          }
        `,
        variables: { type, date, departments, customListOfChanges },
      })
      .then((res) => {
        return _.get(res, "data.rejectChanges");
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_USERS: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            fetchUsersByPage
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        commit("SET_USERS", response.data);
      })
      .catch(() => {});
  },
  FETCH_USERS_BY_PAGE: ({ commit }, { page, pageSize, searchString, startDate, endDate, mode }) => {
    return graph
      .query({
        query: gql`
          query (
            $page: Int
            $pageSize: Int
            $searchString: String
            $startDate: String
            $endDate: String
            $mode: String
          ) {
            fetchUsersByPage(
              page: $page
              pageSize: $pageSize
              searchString: $searchString
              startDate: $startDate
              endDate: $endDate
              mode: $mode
            )
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          page,
          pageSize,
          searchString,
          startDate,
          endDate,
          mode,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  ENCRYPT_USER_INFO: ({ commit }, { userId, pageId }) => {
    return graph
      .query({
        query: gql`
          query ($userId: String, $pageId: String) {
            encryptUserInfo(userId: $userId, pageId: $pageId)
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          userId,
          pageId,
        },
      })
      .then((response) => {
        return response.data.encryptUserInfo;
      })
      .catch((err) => {
        throw err;
      });
  },
  SAVE_USER_INFO: ({ commit }, { userId, pageId, statePayload }) => {
    return graph
      .query({
        query: gql`
          query ($userId: String, $pageId: String, $statePayload: String) {
            saveUserInfo(userId: $userId, pageId: $pageId, statePayload: $statePayload)
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          userId,
          pageId,
          statePayload,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_POSTS: ({ commit }) => {
    const offset = [-7, 30];
    const dates = offset.map((daysLater) =>
      moment().add(daysLater, "days").local().format("YYYY-MM-DD")
    );

    return graph
      .query({
        query: gql`
          query ($dates: [String]) {
            Bot {
              getPostsByDate(dates: $dates)
            }
          }
        `,
        variables: {
          dates,
        },
        fetchPolicy: "network-only",
      })
      .then((data) => {
        commit("SET_POSTS", _.get(data, "data.Bot.getPostsByDate"));

        graph
          .query({
            query: gql`
              query {
                Bot {
                  postsFiller
                }
              }
            `,
            fetchPolicy: "network-only",
          })
          .then((data) => {
            commit("SET_POSTS_FILLER", _.get(data, "data.Bot.postsFiller"));
          });
      });
  },
  rollbackBot: ({ commit }, { brain, snapshotId }) => {
    return graph
      .mutate({
        mutation: gql`
        mutation {
          editorAPI {
            rollbackBot(brain: "${brain}", snapshotId: "${snapshotId}")
          }
        }
      `,
      })
      .then((data) => {});
  },
  DESTROY_BOTS: async ({ commit }, { brain, count, dateTimes }) => {
    const res = await graph.mutate({
      mutation: gql`
        mutation {
          editorAPI {
            destroyBots(brain: "${brain}", count: ${count}, dateTimes: ${JSON.stringify(dateTimes)})
          }
        }
      `,
    });
    if (res?.data?.editorAPI?.destroyBots) {
      commit("DELETE_BOT_SNAPSHOTS", res.data.editorAPI.destroyBots);
    }
  },

  FETCH_ALL_INTERACTIONS: ({ state, commit }, variables) => {
    return graph
      .query({
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $messageLimit: Int
            $dates: [String]
            $date: String!
            $searchTerm: String
            $tags: [String]
            $chatSources: [String]
            $contextHistory: String
            $includeInteractions: Boolean
          ) {
            livechatAPI {
              fetchMonitorChats(
                limit: $limit
                offset: $offset
                messageLimit: $messageLimit
                dates: $dates
                date: $date
                searchTerm: $searchTerm
                tags: $tags
                chatSources: $chatSources
                contextHistory: $contextHistory
                includeInteractions: $includeInteractions
              )
            }
          }
        `,
        variables,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const cloneFetchInteractions = _.cloneDeep(response);
        const chats = _.sortBy(
          _.compact(_.get(cloneFetchInteractions, "data.livechatAPI.fetchMonitorChats")),
          (interaction: any) => {
            return -moment(interaction.Timestamp).unix();
          }
        );
        return {
          chats,
          unfilteredChatsLength: chats.length,
        };
      })
      .catch(() => {});
  },
  FETCH_MODELS: ({ commit }) => {
    return rest("get", "faq_list", {}).then((data: any) => {
      commit("SET_FAQ_MODELS", data);
    });
  },

  FETCH_FAQ_DATASET: ({ state, commit }, variables) => {
    return graph
      .query({
        query: gql`
          query {
            faqAPI {
              dataset: getDataset
            }
          }
        `,
        variables,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const dataset = _.get(response, "data.faqAPI.dataset");
        const result = _.map(dataset, (pair) => {
          pair.tagInputVisible = false;
          pair.tagInputValue = "";
          pair.enabled = !!pair.enabled; // Cast to boolean type
          pair.tags = pair.tags || [];
          pair.variations = _.map(pair.variations, (variation) => {
            return variation;
          });
          const isAnswerAnObjectType =
            pair.answer &&
            pair.answer.constructor === Object &&
            Object.keys(pair.answer).length === 0;
          if (isAnswerAnObjectType) {
            pair.answer = "";
          }

          return pair;
        });
        commit("SET_FAQ_DATASET", {
          dataset: result,
        });
        commit("SET_FAQ_SUPPORTED_LANGUAGES", {
          dataset: result,
        });
        return result;
      })
      .catch(() => {});
  },

  // FETCH_FAQ_DATASET: ({ commit }) => {
  //   return rest("get", "faq_dataset", {})
  //     .then((data: any) => {
  //       commit("SET_FAQ_DATASET", data);
  //     })
  //     .catch((err) => {
  //       Sentry.captureException(err);
  //       throw err;
  //     });
  // },

  ADD_FAQ_VARIATION: ({ commit }, payload) => {
    return rest("post", "faq_add_variation", payload).then((data: any) => {
      commit("SET_FAQ_DATASET", data);
    });
  },
  ADD_FAQ_LABEL: ({ commit }, payload) => {
    return rest("post", "faq_add_label", payload).then((data: any) => {
      commit("SET_FAQ_DATASET", data);
    });
  },
  EXPORT_FAQ_FOR_TESTING: (store, payload) => {
    const output = _.map(store.state.training.dataset, (row: FAQ) => {
      return {
        question: row.question,
        answer: row.answer,
      };
    });

    const link = document.createElement("a");
    link.download = "TEST_Exact.json";
    link.href = "data:application/json," + encodeURIComponent(JSON.stringify(output, null, 2));
    link.click();
  },
  EXPORT_VARIATIONS_FOR_TESTING: (store, payload) => {
    const output = _.flatten(
      _.map(store.state.training.dataset, (row: FAQ) => {
        return _.map(row.variations, (variation) => ({
          question: variation.text,
          answer: row.answer,
        }));
      })
    );

    const link = document.createElement("a");
    link.download = "TEST_Variations.json";
    link.href = "data:application/json," + encodeURIComponent(JSON.stringify(output, null, 2));
    link.click();
  },
  EXPORT_CONTENT_FOR_TESTING: (store, payload) => {
    const output = store.state.nodes.content;
    const link = document.createElement("a");
    link.download = "TEST_Content.json";
    link.href = "data:application/json," + encodeURIComponent(JSON.stringify(output, null, 2));
    link.click();
  },
  SAVE_FAQ_DATASET: (store, payload) => {
    // Only used in FAQ v1.0
    return rest("post", "faq_save_dataset", payload)
      .then((data) => {})
      .catch((err) => {
        throw err;
      });
  },
  UNARCHIVE_TRAINING_RECORD: ({ commit }, payload) => {
    return rest("post", "faq_mark_as_unarchived", payload).then((data: any) => {
      commit("UNARCHIVE_TRAINING_RECORD", payload);
    });
  },
  ARCHIVE_TRAINING_RECORD: ({ commit }, payload) => {
    return rest("post", "faq_mark_as_archived", payload).then((data: any) => {
      commit("ARCHIVE_TRAINING_RECORD", payload);
    });
  },
  ARCHIVE_ARRAY_TRAINING_RECORD: ({ commit }, payload) => {
    return rest("post", "faq_mark_as_archived", payload).then((data) => {
      commit("ARCHIVE_ARRAY_TRAINING_RECORD", payload);
    });
  },
  TEST_FAQ: (store, payload) => {
    return rest("post", "faq_test", payload)
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        throw err;
      });
  },
  PUBLISH_FAQ: (store, modelId) => {
    return rest("post", "faq_publish", { modelId });
  },
  TRAIN_FAQ: (store, payload) => {
    return rest("post", "faq_train", payload)
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        throw err;
      });
  },
  SCORE_FAQ: ({ commit }, payload) => {
    return graph
      .query({
        query: gql`
          query {
            Alexandria {
              score
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        commit("SET_MODEL_SCORE", _.get(res, "data.Alexandria.score"));
      });
  },

  EDIT_GREETING: ({ commit }, greeting: string) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($greeting: String) {
            editorAPI {
              saveGreeting(greeting: $greeting)
            }
          }
        `,
        variables: {
          greeting,
        },
      })
      .then(() => {
        rest("get", "facebook_config", {});
      });
  },
  LOGOUT: ({ state, commit, dispatch }) => {
    LocalStorageManager.removeUnreadMessageList();

    const authService = getInstance();
    const logoutOptions: any = {};
    const { key, url } = authService.getLogoutRedirectSetting();

    logoutOptions[key] = url;

    LocalStorageManager.removeItem("profile");

    graph
      .mutate({
        mutation: gql`
          mutation {
            logout
          }
        `,
      })
      .finally(() => {
        commit("SET_CURRENT_TAB_LOGGED_OUT", true);
        authService.logout(logoutOptions);
      })
      .catch((error) => {});
  },
  SAVE_CHANGES: ({ state }, date: any) => {
    // SAVE_CHANGES: (state: any, date: any) => {
    if (!date) {
      MessageBox("Date is not selected");
      return;
    }

    const postsOnDay = state.posts[date];
    if (!postsOnDay) {
      MessageBox("There are no posts for the day.");
      return;
    }

    const day = postsOnDay.slice(0, 5).map((card: CardType) => {
      return {
        text: card.text,
        subtext: card.subtext.substring(0, 80),
        image: card.image,
        url: card.url,
        buttons: card.buttons,
      };
    });

    // for compatibility with function previously written for falcor
    const postsByDate: { [index: string]: { value: any } } = {};

    postsByDate[date] = {
      value: day,
    };

    graph.mutate({
      mutation: gql`
        mutation ($postsByDate: JSON) {
          savePostsByDate(postsByDate: $postsByDate)
        }
      `,
      variables: {
        postsByDate,
      },
    });
  },
  TEST_SUBSCRIPTION: (store, { date }) => {
    return rest("post", "subscription_test", { selectedDate: date }).catch((err) => {
      throw err;
    });
  },

  SAVE_MODULES: ({ state, commit }, { modules }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($modules: JSON) {
            setModules(modules: $modules)
          }
        `,
        variables: {
          modules,
        },
      })
      .then((response) => {
        setAutoSessionLocalStorage();
        commit("SET_MODULES", {
          Bot: { modules: _.get(response, "data.setModules") || response },
        });
      });
  },

  SAVE_MENU: ({ state }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($menu: [JSON]) {
            editorAPI {
              savePersistentMenu(menu: $menu)
            }
          }
        `,
        variables: {
          menu: state.persistentMenu,
        },
      })
      .then(() => {
        rest("get", "facebook_config", {});
      });
  },
  CREATE_TRIGGER_NODE: ({ state, commit, dispatch }, { id, gotoTarget, node }) => {
    commit("CREATE_TRIGGER_NODE", {
      id,
      gotoTarget,
      node,
    });
    dispatch("UPDATE_TRIGGER_DIFF");
  },
  UPDATE_TRIGGER_DIFF: ({ state, commit }) => {
    const triggerDiff = _.reduce(
      state.nodes.trigger,
      (accum, value, key) => {
        const triggerType = _.get(value, "type", "text");
        const conditions = _.get(value, "conditions", []);
        const entityConditions = _.get(value, "entityConditions", []);
        const stringifiedConditions = JSON.stringify(conditions);
        const stringifiedEntities = JSON.stringify(entityConditions);
        const context = value.context || "";
        const pattern = value.pattern || "";
        const nodeTriggerGroupKey = `${stringifiedConditions},${context},${
          triggerType === "entity" ? stringifiedEntities : pattern
        }`;
        accum[nodeTriggerGroupKey]
          ? (accum[nodeTriggerGroupKey][key] = true)
          : (accum[nodeTriggerGroupKey] = {
              [key]: true,
            });
        return accum;
      },
      {} as { [customIdentifier: string]: { [nodeId: string]: boolean } }
    );
    commit("UPDATE_TRIGGER_DIFF", triggerDiff);
  },
  SELECT_NODE: ({ state, commit, dispatch }, select) => {
    commit("NODE_NAVIGATION", {
      type: state.activeNodeType,
      id: state.activeNodeId,
    });

    commit("SELECT_NODE", select);
    updateUrlQuery({
      editorTab: select.type,
      selectedNode: select.id,
    });

    if (select.type == "content") {
      dispatch("GET_CONNECTED_NODES", select.id);
    }
  },
  SELECT_PREVIOUS_NODE: ({ state, commit }) => {
    const select = state.nodeNavigationStack.shift();
    if (select) {
      commit("SELECT_NODE", select);
    }
  },
  DELETE_NODE: (store, { type, id, old_values }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($node: JSON, $id: String, $type: String, $old_values: JSON) {
            setNode(node: $node, id: $id, type: $type, old_values: $old_values)
          }
        `,
        variables: { id, type, old_values },
      })
      .then((data) => {
        store.commit("DELETE_NODE", { type, id });
        store.commit("SET_CHANGE_CONTENT_NODE", true);
      });
  },
  DELETE_RENAMED_OLD_NODE: (store, { type, id, old_values }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($node: JSON, $id: String, $type: String, $old_values: JSON) {
            setNode(node: $node, id: $id, type: $type, old_values: $old_values)
          }
        `,
        variables: { id, type, old_values },
      })
      .then((data) => {
        store.commit("DELETE_RENAMED_OLD_NODE", { type, id });
      });
  },
  SAVE_ALL_NEW_NODES: async (store, { nodesObject, oldNodesObject }) => {
    return graph.mutate({
      mutation: gql`
        mutation ($nodesObject: JSON!, $oldNodesObject: JSON) {
          saveAllNewNodes(nodesObject: $nodesObject, oldNodesObject: $oldNodesObject)
        }
      `,
      variables: { nodesObject, oldNodesObject },
    });
  },
  UPDATE_ALL_KEYWORDS: (store) => {
    const keywords = _.reduce(
      store.state.nodes.content,
      (accu: any, val: any, key: string) => {
        if (val.keywords && val.keywords.length) {
          const kwords = val.keywords;
          kwords.forEach((keyword: any) => {
            accu[keyword.value] = key;
          });
        }

        return accu;
      },
      {}
    );

    store.commit("SET_KEYWORDS", keywords);
  },
  EDIT_NODE: (store, { old_values, new_values, type, id, node, isRename }) => {
    // Strip empty values
    // const _node = node;
    // if (type === 'content') {
    //   if (Array.isArray(node.content)) {
    //     node.content.forEach(card => {
    //       card.buttons = card.buttons.map(button => _.pickBy(button, val => val));
    //     });
    //   } else {
    //     if (node.content.butons) {
    //       node.content.buttons = node.content.buttons.map(button => _.pickBy(button, val => val));
    //     }
    //   }
    // }

    // check if node conditions is valid, if not remove from array
    if (node.conditions && _.isArray(node.conditions)) {
      node.conditions.forEach((condition: any, index: number) => {
        const isExistsOrNotExistsOperator: boolean = ["EXISTS", "NOT EXISTS"].includes(
          condition.operator
        );
        const valueTypeOtherThanTextInput =
          condition.valueType !== "STRING" && condition.valueType !== "JSON";

        // default to is invalid
        let isValid: boolean = false;

        // For exists or not exists condition only property and operator are required
        if (isExistsOrNotExistsOperator || valueTypeOtherThanTextInput) {
          isValid = !_.isEmpty(condition.property) && !_.isEmpty(condition.operator);
        } else {
          isValid =
            !_.isEmpty(condition.property) &&
            !_.isEmpty(condition.value) &&
            !_.isEmpty(condition.operator);
        }

        // Remove conditions if is not valid
        if (!isValid) {
          node.conditions.splice(index, 1);
        }
      });
    }

    return graph
      .mutate({
        mutation: gql`
          mutation (
            $node: JSON
            $id: String
            $type: String
            $old_values: JSON
            $new_values: JSON
            $isRename: Boolean
          ) {
            setNode(
              node: $node
              id: $id
              type: $type
              old_values: $old_values
              new_values: $new_values
              isRename: $isRename
            )
          }
        `,
        variables: { node, id, type, old_values, new_values, isRename },
      })
      .then((response) => {
        const data = _.get(response, "data.setNode");
        if (data) {
          store.commit("SET_NODE", { type, id, node });
          store.dispatch("UPDATE_ALL_KEYWORDS");
        } else {
          throw new Error("Data not saved");
        }
      })
      .catch((err) => {
        throw err;
      });
  },
  SEND_REJECTION_EMAIL: (store, { from, to, cc, name, message, customListOfChanges }) => {
    return graph
      .query({
        query: gql`
          query (
            $to: String!
            $from: String!
            $cc: String
            $name: String!
            $message: String!
            $customListOfChanges: [JSON]
          ) {
            sendRejectionEmail(
              to: $to
              from: $from
              cc: $cc
              name: $name
              message: $message
              customListOfChanges: $customListOfChanges
            )
          }
        `,
        variables: { from, to, cc, name, message, customListOfChanges },
      })
      .then((res) => {
        return _.get(res, "data.sendRejectionEmail");
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_EMAIL_PREVIEW: (store, { from, to, cc, name, message, customListOfChanges }) => {
    return graph
      .query({
        query: gql`
          query (
            $to: String!
            $from: String!
            $cc: String
            $name: String!
            $message: String!
            $customListOfChanges: [JSON]
          ) {
            getEmailPreview(
              to: $to
              from: $from
              cc: $cc
              name: $name
              message: $message
              customListOfChanges: $customListOfChanges
            )
          }
        `,
        variables: { from, to, cc, name, message, customListOfChanges },
      })
      .then((res) => {
        return _.get(res, "data.getEmailPreview");
      })
      .catch(() => {});
  },
  FETCH_CONTENT_SNAPSHOTS: (store) => {
    return graph
      .query({
        query: gql`
          query {
            editorAPI {
              getContentRevisions
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        store.commit("SET_SNAPSHOT_HISTORY", response.data);
      })
      .catch(() => {});
  },
  FETCH_BUTTON_STATS: (store, id) => {
    return graph
      .query({
        query: gql`
          query {
            editorAPI {
              getButtonStats(id:"${id}")
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data?.editorAPI?.getButtonStats;
      })
      .catch(() => {});
  },
  FETCH_BOT_SNAPSHOTS: (store, { brain }) => {
    return graph
      .query({
        query: gql`
        {
          Bot {
            getBotSnapshots(brain:"${brain}")
          }
        }
      `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        store.commit("SET_BOT_SNAPSHOTS", response.data);
      })
      .catch(() => {});
  },
  FETCH_FAQ_SNAPSHOTS: (store, { brain }) => {
    return graph
      .query({
        query: gql`
        {
          Bot {
            getFAQSnapshots(brain:"${brain}")
          }
        }
      `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        store.commit("SET_FAQ_SNAPSHOTS", response.data);
      })
      .catch(() => {});
  },
  DELETE_FAQ_SNAPSHOTS: (store, { brain, limit }) => {
    return graph
      .mutate({
        mutation: gql`mutation {
        deleteFAQSnapshots(brain: "${brain}", limit:${limit})
      }
      `,
      })
      .then((response) => {
        return response;
      })
      .catch(() => {});
  },
  KONAMI: (store) => {
    store.commit("ACTIVATE_EDITOR");
    store.commit("ACTIVATE_ADVANCED_EDITOR");
    store.commit("ACTIVATE_DEVELOPER_CONSOLE");
  },

  FETCH_RATINGS: ({ commit }, payload) => {
    return graph
      .query({
        query: gql`
          query ($channel: String, $startDate: String, $endDate: String) {
            Bot {
              ratings(channel: $channel, startDate: $startDate, endDate: $endDate)
            }
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          channel: _.get(payload, "data.channel", ""),
          startDate: _.get(payload, "data.startDate", ""),
          endDate: _.get(payload, "data.endDate", ""),
        },
      })
      .then((res) => {
        commit("SET_RATINGS", _.get(res, "data.Bot.ratings"));
      });
  },

  FETCH_RATING_CHANNELS: ({ commit }, payload) => {
    return graph
      .query({
        query: gql`
          query {
            Bot {
              ratingChannels
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        commit("SET_RATING_CHANNELS", _.get(res, "data.Bot.ratingChannels"));
      });
  },

  FETCH_FILE_URL: ({ state }, fileUrl) => {
    if (fileUrl) {
      const urlObj = new URL(fileUrl);
      const blobName = urlObj.searchParams.get("blobName") || urlObj.pathname;
      const result = rest("post", "webchat_request_file_agent", {
        blobName,
      }).catch((error) => {
        // console.error("webchat_request_file_agent", error);
      });
      return result;
    }
  },
  FETCH_MULTIPLE_FILE: async ({ state, dispatch }, fileUrls) => {
    const promises = _.map(fileUrls, (item, index) => {
      return dispatch("FETCH_FILE_URL", item).catch((error) => {
        throw new Error("Fetching multiple file error");
      });
    });
    const URLs = Promise.all(promises);
    return URLs;
  },
  FETCH_VISUALIZATION_DATA: async ({ state, dispatch, commit }) => {
    commit("SET_PROCESSING_VISUALIZATION", true);

    const processedForClassifier3Dataset = _.reduce(
      state.training.dataset,
      (accum, intent) => {
        const questionArray: any = _.map(intent.variations, (variation) => {
          return { question: intent.intentName, answer: variation.text };
        });
        return accum.concat(questionArray);
      },
      []
    );

    const requestData = {
      questions: processedForClassifier3Dataset,
    };
    const uploadConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        project_id: "datavisualization",
        model_id: "datavisualizationmodel",
      },
    };

    let uploadResponse;
    try {
      uploadResponse = await axios.post(
        "https://classifier3.app.keyreply.com/ie/import/json",
        requestData,
        uploadConfig
      );
    } catch (err) {
      console.log(err);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        project_id: _.get(uploadResponse, "data.data.project_id"),
        model_id: _.get(uploadResponse, "data.data.model_id"),
        data_id: _.get(uploadResponse, "data.data.data_id"),
        // project_id: "datavisualization",
        // model_id: "datavisualizationmodel",
        // data_id: "20200808-160615"
      },
    };

    try {
      const response = await axios.get(
        "https://classifier3.app.keyreply.com/ie/evaluate/qbox",
        config
      );
      //response.data is a string, below process will convert it to the right format
      const dataSplitByLine = response.data.split("\n");
      dataSplitByLine.shift();
      const data = _.map(dataSplitByLine, (line) => {
        const questionRow: any = [];
        for (let i = 0; i < 5; i++) {
          const lastComma = line.lastIndexOf(",");
          const p = line.slice(lastComma + 1);
          line = line.slice(0, lastComma);
          questionRow.unshift(p);
          if (i === 4) {
            questionRow.unshift(line);
          }
        }

        const output = {
          Question: questionRow[0],
          "Expected intent": questionRow[1],
          "Actual intent": questionRow[2],
          Confidence: parseFloat(questionRow[3]),
          Rank: parseInt(questionRow[4]),
          "Is expected?": questionRow[5],
        };

        return output;
      });

      commit("SET_VISUALIZATION_DATA", data);
      commit("SET_PROCESSING_VISUALIZATION", false);
      return true;
    } catch (err) {
      console.log(err);
      commit("SET_PROCESSING_VISUALIZATION", false);
      return false;
    }
  },
  SET_ALL_SESSION_DIALOG: async ({ state }, value) => {
    rest("post", "session_timeout", {
      action: "showDialog",
      data: value,
    });
  },
  RENEW_TIMEOUT: async ({ state }, value) => {
    rest("post", "session_timeout", {
      action: "renewTimeout",
    });
  },
  LOG_ALL_SESSION_OUT: async ({ state }) => {
    rest("post", "session_timeout", {
      action: "logAllOut",
    });
  },
  TRENDING_TOPICS_FIRST_TIME: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            data: getRecordsFirstTime
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.data;
        commit("SET_TRENDING_TOPICS", result);
      });
  },
  TRENDING_TOPICS: async ({ commit }) => {
    await graph
      .query({
        query: gql`
          query {
            data: getCurrentlyAndPreviousTrending
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const { currently, previous } = res.data.data;
        commit("SET_CURRENTLY_TOPICS", currently);
        commit("SET_PREVIOUS_TOPICS", previous);
      });
  },
  TRENDING_TOPICS_FILTERD_BY_RANGE: async ({ commit }, { startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query ($startDate: String!, $endDate: String!) {
            data: getRecordFilterdByRange(startDate: $startDate, endDate: $endDate)
          }
        `,
        variables: { startDate, endDate },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.data;
        commit("SET_TRENDING_TOPICS", result);
      });
  },
  CURRENTLY_TOPICS_FILTER_BY_RANGE: async ({ commit }, { startDate, endDate }) => {
    return graph
      .query({
        query: gql`
          query ($startDate: String!, $endDate: String!) {
            data: getCurrentlyRecordFilterByRange(startDate: $startDate, endDate: $endDate)
          }
        `,
        variables: { startDate, endDate },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.data;
        commit("SET_CURRENTLY_TOPICS", result);
      });
  },
  AUDIT_AUTHORIZED_WIDGET: async (context, { widgetInfo, tokenPayload }) => {
    return graph.mutate({
      mutation: gql`
        mutation ($widgetInfo: String, $tokenPayload: JSON) {
          auditAuthorizedWidget(widgetInfo: $widgetInfo, tokenPayload: $tokenPayload)
        }
      `,
      variables: { widgetInfo, tokenPayload },
      fetchPolicy: "no-cache",
    });
  },
};

export default actions;
